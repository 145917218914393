import { TextField } from "@mui/material";
import React, { useState } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const AskAnythink = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="text-center" style={{ marginTop: "15px", marginBottom: "15px" }}>
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2 className="mobile-any-heading">Anything else, you want to know</h2>
          <p style={{fontSize: "14px"}}>
            Ask more questions for greater knowledge and clarity. We are here to serve and support you.
          </p>
          <div>
            <TextField
              className="mobile-search"
              sx={{ width: "50%", margin: "30px" }}
              InputLabelProps={{
                sx: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                }
            }}
              label="Ask anything to fast education"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <a href={`https://api.whatsapp.com/send/?phone=	7489856895&text=${searchQuery ? searchQuery : 'Hi+Team%2C+is+there+any+related+service+available+%3F'}&type=phone_number&app_absent=0`} target="_blank" rel="noreferrer">
                    <WhatsAppIcon />
                    </a>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
