import React, { useRef } from "react";
import { Navigation } from "./navigation";
import { TestSeries } from "./TestSeries";
import { Footer } from "./Footer";

const PlanLists = () => {
    const childRef = useRef();
    const cartNumberUpdate = () => {
        if (childRef.current !== null || childRef.current !== undefined) {
            try {
                childRef.current.update();
            } catch (error) {
                console.log(error);
            }

        }

    }

    return (
        <div>
            <Navigation ref={childRef} />
            <TestSeries cartNumberUpdate={cartNumberUpdate} />
            <Footer />
        </div>
    )
};

export default PlanLists;