import { Badge, Box, Button, IconButton, useMediaQuery } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Base64 } from 'js-base64';

export const Navigation = forwardRef((props, ref) => {

  const isMobileDevice = useMediaQuery('(min-width:480px)');

  // console.log('isMobileDevice', isMobileDevice);
  let cartData = localStorage.getItem('cartArray');
  const navigate = useNavigate();
  const [cartItem, setCartItem] = useState([])
  const [refresh, setrefresh] = useState(false)
  const handleLogoClick = () => {
    navigate('/');

  };

  useImperativeHandle(ref, () => ({

    update() {
      handleCartCount()
    }

  }));

  const handleCartCount = () => {
    setrefresh(!refresh)
  }
  useEffect(() => {
    if (cartData !== null && cartData !== undefined) {
      setCartItem(cartData ? JSON.parse(cartData) : [])
    }
  }, [cartData, refresh])

  const handleCartClick = () => {
    let courseItem = localStorage.getItem('selectCourse');
    let data = Base64.encode(JSON.stringify({ courseObj: JSON.parse(courseItem) }), true)
    navigate('/test-series?data=' + data, { state: { cartRoute: "cartRoute" } })
  }

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className={!isMobileDevice ? "mobile-nav" : "navbar-header"}>
          <img src="img/FTS logo-03.png" className="img-responsive logo-image" alt="" style={{ width: "150px" }} onClick={handleLogoClick} />
          <Box>
            {
              !isMobileDevice && <>
                {cartItem?.length > 0 && (

                  <IconButton onClick={handleCartClick} sx={{ position: 'relative', padding: '12px', mt: 2.8, marginRight: "20px" }}>
                    <Badge badgeContent={cartItem.length} color="error" sx={{ top: '-10px', left: '10px' }}>
                      <AddShoppingCartIcon sx={{ fontSize: '32px', color: "#fff" }} />
                    </Badge>
                  </IconButton>

                )}
              </>
            }
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
          </Box>

        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right top-menubar">
            <li>
              <a href="/" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="#whyfast" className="page-scroll">
                Why FTS
              </a>
            </li>
            <li>
              <a href="#vissionmission" className="page-scroll">
                About FTS
              </a>
            </li>
            <li>
              <a href="#testseries" className="page-scroll">
              Select your Test Series
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact Us
              </a>
            </li>
            <li>
              <Button sx={{ mt: 1.5, background: "#DD2A3D", color: "#fff", fontWeight: "bold", padding: "11px 25px", fontSize: "12px", margin: "9px 20px 0" }}>Download App now</Button>
            </li>
            {isMobileDevice && cartItem?.length > 0 && (
              <li>
                <IconButton onClick={handleCartClick} sx={{ position: 'relative', padding: '12px', mt: 1.5 }}>
                  <Badge badgeContent={cartItem.length} color="error" sx={{ top: '-10px', left: '10px' }}>
                    <AddShoppingCartIcon sx={{ fontSize: '32px', color: "#fff" }} />
                  </Badge>
                </IconButton>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
});
