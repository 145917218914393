import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function ViewPlanModal({ plansList, courseContentList, selectShedule, handleChange }) {

    const [subjectList, setSubjectList] = useState([])
    const [allPlanListData, setAllPlanListData] = useState([])
    const [schedule, setSchedule] = useState('');

    useEffect(() => {
        extractAndPushChildren(plansList);
    }, [plansList]);

    const extractAndPushChildren = (data) => {
        const newData = data.map((item) => {
            if (item.children) {
                const updatedSecondLevelChildren = item.children.map((secondLevelItem) => {
                    if (secondLevelItem.children) {
                        const updatedThirdLevelChildren = [];
                        secondLevelItem.children.forEach((thirdLevelItem) => {
                            updatedThirdLevelChildren.push(thirdLevelItem);
                            if (thirdLevelItem.children) {
                                updatedThirdLevelChildren.push(...thirdLevelItem.children);
                            }
                        });
                        return {
                            ...secondLevelItem,
                            children: updatedThirdLevelChildren,
                        };
                    }
                    return secondLevelItem;
                });
                return {
                    ...item,
                    children: updatedSecondLevelChildren,
                };
            }
            return item;
        });

        const maxChildren = Math.max(
            ...newData.map((plan) =>
                plan.children?.reduce((acc, child) => acc + (child.children?.length || 0), 0) || 0
            )
        );

        setSubjectList(maxChildren);
        setAllPlanListData(newData);
    };

    const handleChangePlan = (event) => {
        const selectedValue = event.target.value;
        setSchedule(selectedValue);

        if (selectedValue === 'all') {
            extractAndPushChildren(plansList);
        } else if (selectedValue?.id) {
            const filterPlan = plansList.filter((item) => selectedValue.id === item.id);
            extractAndPushChildren(filterPlan);
        }
    };
console.log('selectShedule', selectShedule);


    return (
        <React.Fragment>
            <Box
                sx={{
                    padding: "2rem",
                    mt: 3
                }}
            >
                <Box sx={{ textAlign: "left" }}>
                    <Typography variant="h3" fontWeight={'bold'} sx={{ mb: 4 }}>Plan List</Typography>
                    <FormControl className='mobile-select-button'>
                        <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>Schedule</InputLabel>
                        <Select
                            className='select-option'
                            sx={{ mb: 2, minWidth: "100px", maxWidth: "300px", fontSize: "10px", width: "200px", fontSize: "12px", mr: 2  }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Schedule"
                            value={selectShedule}
                            onChange={handleChange}
                        >
                            {
                                courseContentList && courseContentList.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data}>{data?.title}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl className='mobile-select-button'>
                        <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>Plan</InputLabel>
                        <Select
                            className='select-option'
                            sx={{ mb: 2, minWidth: "250px", maxWidth: "300px", fontSize: "10px", mr: 2 }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Plan"
                            value={schedule}
                            onChange={handleChangePlan}
                        >
                            <MenuItem value="all">All</MenuItem>
                            {
                                plansList && plansList.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data}>{data?.title}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{mb:2, mt:1}}>
                    <Typography variant="p" sx={{fontSize: "12px", mb:1, mt:1, color: "rgba(0, 0, 0, 0.87)"}}>{selectShedule?.title === "UnScheduled" ? "It is recommended to submit the answer sheet within 1-3 days of the exam date, however you can also submit it later for checking." : "Question papers are available as per this schedule. You can submit copies for checking any time before the start of the CA exams"}</Typography>
                </Box>
                <Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" sx={{
                            '& .MuiTableCell-root': {
                                border: '1px solid rgba(224, 224, 224, 1)',
                            },
                        }}>
                            <TableHead>
                                <TableRow sx={{ background: "#ff0000c9" }}>
                                    {allPlanListData.map((plan, i) => (
                                        <TableCell key={i} colSpan={2} align="left" sx={{ fontWeight: "bold", fontSize: "14px", borderRight: i < allPlanListData.length - 1 ? '1px solid #000 !important' : '', }}>{plan?.title}</TableCell>
                                    ))}
                                </TableRow>
                                <TableRow sx={{ background: "#ff000036" }}>
                                    {allPlanListData.map((plan, i) => (
                                        <React.Fragment key={i}>

                                            <TableCell sx={{ fontSize: "12px", fontWeight: "bold" }}>Subject</TableCell>
                                            <TableCell sx={{ borderRight: i < allPlanListData?.length - 1 ? '1px solid #000 !important' : "", fontSize: "12px", fontWeight: "bold" }}>{selectShedule?.title === "UnScheduled" ? "Available From" : "Exam Date"}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.from({ length: subjectList }).map((_, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {allPlanListData.map((plan, planIndex) => {
                                            const children = plan.children?.flatMap(child => child.children || []) || [];
                                            const child = children[rowIndex];
                                            return (
                                                <React.Fragment key={planIndex}>
                                                    {
                                                        child?.entityType === "folder" ? <>
                                                            <TableCell colSpan={2} sx={{ fontWeight: "bold", borderRight: planIndex < allPlanListData?.length - 1 ? '1px solid #000 !important' : "", fontSize: "12px" }}>
                                                                {child?.title}
                                                            </TableCell>
                                                        </> : <>
                                                            <TableCell sx={{ fontSize: "11px" }}>
                                                                {child?.title}
                                                            </TableCell>
                                                            <TableCell sx={{ borderRight: planIndex < allPlanListData?.length - 1 ? '1px solid #000 !important' : "", fontSize: "11px" }}>
                                                                {child?.createdAt ? new Date(child?.createdAt).toLocaleDateString() : ""}
                                                            </TableCell>
                                                        </>
                                                    }
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </React.Fragment>
    );
}