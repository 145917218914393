import React from "react";
import backgroundImg2 from "../components/image/FAST TEST SERIES0.png"
import { Box } from "@mui/material";

export const SecondSection = () => {
  return (
    <div id="menu" className="text-center">
      <div className="" style={{ padding: '8rem 0 0 0' }}>
        <div className="col-xs-12 col-md-12 col-lg-12">
          <Box className="mobile-home-image">
            <img src={backgroundImg2} className="" alt="" style={{ width: '100%' }} />
          </Box>
          <img src="img/Group 1000001656.png" className="first-section-img home-image" alt="" style={{ width: "100%", padding: "0 80px" }} />
        </div>
      </div>
    </div>
  );
};
