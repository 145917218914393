import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import { Base64 } from 'js-base64';


export const SectionTestSeries = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const BASE_URL = "https://prodapi.classiolabs.com/";
    // let Endpoints = ''
    const InstId = 119;
    // const InstId = 49;
    const location = useLocation();
    const navigate = useNavigate()
    const queryParam = new URLSearchParams(location.search);
    const campaignId = queryParam.get("campaignId");
    const [selectedTag, setSelectedTag] = useState('');
    const [course, setCourse] = useState([]);
    const [filterCourse, setFilterCourse] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [Endpoints, setEndpoints] = useState('')

    useEffect(() => {
        getCourseList();
        getTagsList();
        getInstituteDetail();
    }, []);

    useEffect(() => {
        if (selectedTag) {
            const filterCourseTags = course.filter(item => {
                const tagslists = item.tags || [];
                if (tagslists.some(tag => tag.id === selectedTag.id)) {
                    return item
                }
            });
            setFilterCourse(filterCourseTags);
            // setSelectedIds([filterCourseTags[0]?.id])
        }
    }, [selectedTag, course])

    const getTagsList = async () => {
        try {
            let requestOptions = {
                // headers: { "X-Auth": token },
                withCredentials: false,
            };
            const response = await axios.get(BASE_URL + "admin/course/fetch-tags-public/" + InstId, requestOptions);
            if (response?.data?.errorCode === 0) {
                setTagsList(response?.data?.tags)
                setSelectedTag(response?.data?.tags[1])
            };

        } catch (error) {
            console.log(error);
        }
    };

    const getCourseList = async () => {
        try {
            let requestOptions = {
                // headers: { "X-Auth": token },
                withCredentials: false,
            };
            const response = await axios.get(BASE_URL + "admin/course/fetch-public/" + InstId, requestOptions);
            if (response?.data?.errorCode === 0) {
                setCourse(response?.data?.courses);
            };
        } catch (error) {
            console.log(error);
        }
    };

    const getInstituteDetail = async () => {
        try {
            let requestOptions = {
                // headers: { "X-Auth": token },
                withCredentials: false,
            };
            const response = await axios.get(
                BASE_URL + "/getMetaData/fetch-institute/" + InstId,
                requestOptions
            );
            if (response?.data?.errorCode === 0) {
                setEndpoints(response?.data?.instituteTechSetting?.mediaUrl)
                // Endpoints = response?.data?.instituteTechSetting?.mediaUrl
            };
        } catch (error) {
            console.log(error);
        }
    };

    const handleTags = (event) => {
        setSelectedTag(event.target.value);
        getCourseList()
    };

    const handleTestDetail = (item) => {
        let data = Base64.encode(JSON.stringify({ courseObj: item }), true)
        navigate('/test-series?data=' + data)
    }

    return (
        <div id="testseries" className="" style={{ margin: "40px", textAlign: 'left', padding: '8rem 0 0 0' }}>
            <div className="row" style={{ marginLeft: "4%", marginRight: "2%" }}>
                <div className="col-xs-12 col-md-12 col-lg-12">
                    <div>
                        <h2 className='mobile-text-high' style={{
                            textTransform: "initial",
                            display: "flex", alignItems: "center", textAlign: "left", fontWeight: "bold", marginBottom: "15px", justifyContent: "left", marginTop: "24px",
                        }}>Highly Rated   <span style={{ color: 'red', marginLeft: "5px", marginRight: "5px" }}>FAST</span> Test Series Program</h2>
                        {/* <p sx={{ mb: 3 }}>Crack CA-CS with Our Trusted and Most Loved Test Series.</p> */}
                        <p sx={{ mb: 3 }}> Join now. Select your variant below.</p>

                    </div>
                    <Grid container sx={{ pt: 2, pb: 5 }}>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <Stack direction={"row"} spacing={3} className='stack-mobile'>
                                <FormControl className='mobile-select-button'>
                                    <InputLabel id="demo-simple-select-label" sx={{ fontSize: "14px" }}>Select Variant</InputLabel>
                                    <Select
                                        className='select-option'
                                        sx={{ mb: 2, width: 250, fontSize: "15px" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedTag}
                                        label="Select Variant"
                                        onChange={handleTags}
                                    >
                                        {
                                            tagsList && tagsList.map((data, index) => {
                                                return (
                                                    <MenuItem key={index} value={data}>{data?.tag}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box sx={{ ml: 3 }} className='mobile-suggested'>
                        <Carousel
                            customTransition="all 1s ease-in-out"
                            className=''
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            // customTransition="all .5"
                            transitionDuration={1000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            //   deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                        >
                            {
                                filterCourse?.length > 0 && filterCourse.map((course, i) => {
                                    if (course?.active === true) {
                                        return <Grid item xs={12} sm={2.3} md={2.3} lg={2.3} sx={{ marginBottom: 3 }}>
                                            <Box sx={{ margin: "10px", cursor: "pointer" }} onClick={() => handleTestDetail(course)} >
                                                <img src={Endpoints + course.logo} alt="cardthumbimage" style={{ width: "100%" }} />
                                                <Stack gap={'0.5rem'} pl={'1rem'} pr={'1rem'}>
                                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} textAlign={"left"}>
                                                        <p style={{ width: "100%", fontWeight: "bold", margin: 0, color: '#000', marginTop: "10px", marginBottom: "10px", fontSize: "20px", textAlign: "center" }}>
                                                            {course.title}
                                                        </p>
                                                    </Stack>
                                                    {/* {
                                                    course.paid ?
                                                        <>
                                                            {
                                                                course.discount > 0 ? <p style={{ fontWeight: "bold", color: "#f59f00", display: "flex", fontSize: "11px", margin: 0 }}>
                                                                    <p>
                                                                        {Number(course.price) - (Number(course.price) * (Number(course.discount) / 100))}/-
                                                                    </p>

                                                                    <p style={{ color: "#e5dfdf" }}> &nbsp; <s>{course.price}/-</s> &nbsp;{course.discount}%</p>
                                                                </p>
                                                                    : <p style={{ fontWeight: "bold", color: "#f59f00" }}>{course.price}/-</p>
                                                            }
                                                        </>
                                                        :
                                                        <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                            Free
                                                        </p>
                                                } */}
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    }

                                })
                            }
                        </Carousel>
                        <div className='desktop-plan-box'>
                            <Grid container>
                                {
                                    filterCourse?.length > 0 && filterCourse.map((course, i) => {
                                        if (course?.active === true) {
                                            return <Grid item xs={12} sm={2.4} md={2.4} lg={2.4}>
                                                <Box sx={{
                                                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                                    margin: "10px", cursor: "pointer"
                                                }} onClick={() => handleTestDetail(course)} >
                                                    <img src={Endpoints + course.logo} alt="cardthumbimage" style={{ width: "100%" }} />
                                                    <Stack gap={'0.5rem'} pl={'1rem'} pr={'1rem'}>
                                                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} textAlign={"left"}>
                                                            <p style={{ width: "100%", fontWeight: "bold", margin: 0, color: '#000', marginTop: "10px", marginBottom: "10px", fontSize: "18px", textAlign: "center" }}>
                                                                {course.title}
                                                            </p>
                                                        </Stack>
                                                        {/* {
                                                        course.paid ?
                                                            <>
                                                                {
                                                                    course.discount > 0 ? <p style={{ fontWeight: "bold", color: "#f59f00", display: "flex", fontSize: "11px", margin: 0 }}>
                                                                        <p>
                                                                            {Number(course.price) - (Number(course.price) * (Number(course.discount) / 100))}/-
                                                                        </p>

                                                                        <p style={{ color: "#e5dfdf" }}> &nbsp; <s>{course.price}/-</s> &nbsp;{course.discount}%</p>
                                                                    </p>
                                                                        : <p style={{ fontWeight: "bold", color: "#f59f00" }}>{course.price}/-</p>
                                                                }
                                                            </>
                                                            :
                                                            <p style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                Free
                                                            </p>
                                                    } */}
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        }

                                    })
                                }
                            </Grid>
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    );
};
