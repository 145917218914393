import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { SecondSection } from "./secondSection";
import { WhyFastTestSeries } from "./WhyFastTestSeries";
import { FtsFeature } from "./FtsFeature";
import { TestSeries } from "./TestSeries";
import { AskAnythink } from "./AskAnythink";
import { Footer } from "./Footer";
import { SectionTestSeries } from "./SectionTestSeries";

const Home = () => {

  // useEffect(()=>{
  //   localStorage.removeItem('addedCartPlans')
  //   localStorage.removeItem('purchaseArray')
  //   localStorage.removeItem('addtoCartIds')
  // }, [])

  return (
    <div>
      <Navigation />
      <SecondSection />
      <WhyFastTestSeries />
      <FtsFeature />
      <SectionTestSeries />
      <AskAnythink />
      <Footer />
    </div>
  )
};

export default Home;