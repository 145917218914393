import { Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import { pink } from '@mui/material/colors';
import backgroundImg2 from "../components/image/FAST TEST SERIES2.png"

export const FtsFeature = () => {
  return (
    <div id="vissionmission" className="text-center" >
      <div className="row fts-full-image" style={{ position: "absolute", right: 0, margin: 0 }}>
        <div className="col-xs-12 col-md-11 col-lg-11">

          <img src={backgroundImg2} className="mobile-home-image" alt="" style={{ width: "100%", padding: "0" }} />
          <img src="img/ft3.png" className="home-image" alt="" style={{ width: "100%", padding: "0" }} />
        </div>
      </div>
      <div className="row" style={{ textAlign: "left", padding: "20px", position: "relative", top: 0, left: 0, top: 0, bottom: 0 }}>
        <div className="col-xs-12 col-md-5 col-lg-5 fts-mobile-col" style={{ marginLeft: "6%" }}>
          <h2 className="mobile-fts-heading" style={{
            textTransform: "initial",
            display: "flex", alignItems: "center", textAlign: "center", fontWeight: "bold", marginBottom: "8px"
          }}><span style={{ color: "#DD2A3D", marginRight: "5px" }}>FTS</span> Feature</h2>
          {/* <Typography variant="h2" fontWeight={"bold"} sx={{ mb: 1 }} className="mobile-fts-heading"></Typography> */}
          {/* <p style={{ marginBottom: "30px" }} className="mobile-fts-heading">"Right direction helps to drive right and reach the destination”. FAST Test Series guide you
            to the right direction and Expert guidance for achieving your goal."</p> */}

          {/* <Grid container sx={{ mb: 1, mt: 1 }}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ display: "flex", alignItems: 'center', color: "black", fontWeight: "500" }}>
              FAST Test Series is a pan India test series for CA Exams.
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 1, mt: 1 }}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ display: "flex", alignItems: 'center', color: "black", fontWeight: "500" }}>
              FAST Test Series is the path from “Being in CA” to “Being a CA”.
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 1, mt: 1 }}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ display: "flex", alignItems: 'center', color: "black", fontWeight: "500" }}>
              FAST Test Series aims to provide maximum benefits to the students
              in terms of preparation and guidance.
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 1, mt: 1 }}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ display: "flex", alignItems: 'center', color: "black", fontWeight: "500" }}>
              FAST Test Series conducting chapter wise and full syllabus test series
              for CA final (new & old course) and CA Intermediate.
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 1, mt: 1 }}>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} sx={{ display: "flex", alignItems: 'center', color: "black", fontWeight: "500" }}>
              We have most experienced team (Chartered Accountants only)
              for making & checking of test papers.
            </Grid>
          </Grid> */}
          {/* <ul style={{ color: "black", textAlign: "left", fontWeight: "500", }}>
            <li style={{ marginTop: "5px", marginBottom: "5px" }}><Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} /> </li>
            <li style={{ marginTop: "5px", marginBottom: "5px" }}><Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} /></li>
            <li style={{ marginTop: "5px", marginBottom: "5px" }}><Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />
              </li>
            <li style={{ marginTop: "5px", marginBottom: "5px" }}><Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} /></li>
            <li style={{ marginTop: "5px", marginBottom: "5px" }}><Checkbox defaultChecked sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} /></li>
          </ul> */}
          {/* <p style={{ color:"black", fontWeight: "bold",marginTop: "20px" }} className="mobile-fts-heading">Font should be same like why FTS</p> */}
          <ul style={{ color: "black", textAlign: "left", fontWeight: "500", marginLeft: "30px" }}>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>FAST Test Series is a pan India test series open for all CA students.</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>FAST Test Series offers Detailed Test series and Full Course test series</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>Detailed test series provides 5 tests, each covering 20% of subject’s syllabus + 1/3 full course tests</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>Full course test series is available in three variants, One, Two & Three Full Course Test series</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>All above are available as group wise or subject wise.</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>We have most experienced team (Chartered Accountants only) for making & checking of test papers.</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>Enroll - Attempt - Upload - Get Your Paper Checked with Marks & Feedbacks</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>Easy to use, Anywhere access Android, iOS & Windows App to enroll, download and upload your papers</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>Papers curated by faculties, checked by trained CAs, offered at best discounted rates.</li>
            <li style={{ marginTop: "5px", marginBottom: "5px", listStyle: "disc" }}>FAST Test Series is the path from “Being in CA” to “Being a CA”.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
